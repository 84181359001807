import { dew as _CONFIG_GETDew } from "./CONFIG_GET";
import { dew as _CONFIG_SETDew } from "./CONFIG_SET";
import { dew as _DELETEDew } from "./DELETE";
import { dew as _EXPLAINDew } from "./EXPLAIN";
import { dew as _LISTDew } from "./LIST";
import { dew as _PROFILEDew } from "./PROFILE";
import { dew as _QUERY_RODew } from "./QUERY_RO";
import { dew as _QUERYDew } from "./QUERY";
import { dew as _SLOWLOGDew } from "./SLOWLOG";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pushQueryArguments = void 0;

  const CONFIG_GET = _CONFIG_GETDew();

  const CONFIG_SET = _CONFIG_SETDew();

  ;

  const DELETE = _DELETEDew();

  const EXPLAIN = _EXPLAINDew();

  const LIST = _LISTDew();

  const PROFILE = _PROFILEDew();

  const QUERY_RO = _QUERY_RODew();

  const QUERY = _QUERYDew();

  const SLOWLOG = _SLOWLOGDew();

  exports.default = {
    CONFIG_GET,
    configGet: CONFIG_GET,
    CONFIG_SET,
    configSet: CONFIG_SET,
    DELETE,
    delete: DELETE,
    EXPLAIN,
    explain: EXPLAIN,
    LIST,
    list: LIST,
    PROFILE,
    profile: PROFILE,
    QUERY_RO,
    queryRo: QUERY_RO,
    QUERY,
    query: QUERY,
    SLOWLOG,
    slowLog: SLOWLOG
  };

  function pushQueryArguments(args, graph, query, timeout) {
    args.push(graph, query);

    if (timeout !== undefined) {
      args.push(timeout.toString());
    }

    return args;
  }

  exports.pushQueryArguments = pushQueryArguments;
  return exports;
}