import { dew as _Dew } from ".";
import { dew as _QUERYDew } from "./QUERY";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;

  const _1 = _Dew();

  var QUERY_1 = _QUERYDew();

  Object.defineProperty(exports, "FIRST_KEY_INDEX", {
    enumerable: true,
    get: function () {
      return QUERY_1.FIRST_KEY_INDEX;
    }
  });
  exports.IS_READ_ONLY = true;

  function transformArguments(graph, query, timeout) {
    return (0, _1.pushQueryArguments)(["GRAPH.RO_QUERY"], graph, query, timeout);
  }

  exports.transformArguments = transformArguments;

  var QUERY_2 = _QUERYDew();

  Object.defineProperty(exports, "transformReply", {
    enumerable: true,
    get: function () {
      return QUERY_2.transformReply;
    }
  });
  return exports;
}